@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.shape-bg-details {
    position: absolute;
    background-image: url("../picture/shape_bg.svg");
    width: 100%;
    height: 100%;
    background-size: 100%;
    z-index: -1;
    pointer-events: none;
    top: -52%;
    left: -16%;
    opacity: 0.4;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.header-details {
    padding: 40px 50px;
    padding-top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
        padding: 20px;
    }
    .btn-back {
        display: flex;
        align-items: center;
        gap: 24px;
        text-decoration: none;
        .icon {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            border: 1px solid var(--gray-200);
            &::before {
                content: "";
                position: absolute;
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='18' viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.375 9.00012L1.625 9.00012' stroke='%234D4752' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.8125 16.5306L20.375 9.00061L12.8125 1.46936' stroke='%234D4752' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-size: 18px;
                width: 18px;
                height: 15px;
                background-repeat: no-repeat;
            }
        }
        span {
            font-size: 22px;
            color: var(--gray-400);
        }
    }
    .logo-product {
        img {
            width: 220px;
        }
    }
}

.details-content-main {
    padding: 0 50px;
    @include media-breakpoint-down(md) {
        padding: 0 20px;
    }
    .title-details {
        h1 {
            font-size: 40px;
            font-weight: $semiBold;
            color: var(--dark);
            margin-bottom: 24px;
        }
    }
    .content-details {
        p {
            font-size: 36px;
            color: var(--gray-800);
            font-weight: $normal;
            margin-bottom: 0;
            @include media-breakpoint-down(sm) {
                font-size: 24px;
            }
        }
    }
    .features-details {
        margin-top: 40px;
        .item-featch {
            background-color: #f7f7f8;
            border: 1px solid #f0eff1;
            text-align: center;
            border-radius: 14px;
            padding: 32px 38px;
            margin-bottom: 24px;
            min-height: 126px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-bottom: 20px;
            }
            p {
                font-size: 20px;
                font-weight: $medium;
                color: var(--gray-700);
                margin: 0;
            }
        }
    }
}

.partners {
    padding-bottom: 30px;
    margin-top: 20px;
    p {
        font-size: 15px;
        color: var(--gray-700);
        margin-bottom: 10px;
    }
    .block {
        display: flex;
        gap: 24px;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    .group-brand {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 14px;
        padding: 15px 30px;
        border: 1px solid #f3f2f3;
        height: 120px;
        width: 280px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

//SCREENS - WORKS

.details-screens-works {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding-left: 40px;
    padding-bottom: 30px;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 20px;
    }
    .tts-block-screen {
        display: grid;
        gap: 50px;
        @include media-breakpoint-down(sm) {
            gap: 20px;
        }
        .item-lg-screen {
            border-radius: 16px;
            padding: 50px 30px;
            position: relative;
            overflow: hidden;
            height: 55vh;
            &::before {
                content: "";
                background-image: url(../images/texture.png);
                width: 100%;
                height: 100%;
                inset: 0;
                position: absolute;
                z-index: 0;
                opacity: 0.2;
                mix-blend-mode: overlay;
                pointer-events: none;
            }
            .title {
                font-size: 30px;
                font-weight: $semiBold;
                color: var(--white);
                margin-bottom: 16px;
            }
            .description {
                font-size: 16px;
                font-weight: $medium;
                color: rgba($white, 0.9);
            }
            .img-screen {
                position: absolute;
                width: 87%;
                bottom: -90px;
                object-fit: contain;
            }
            &.mockup {
                .img-screen {
                    position: absolute;
                    width: 100%;
                    bottom: -20px;
                    left: -36px;
                    object-fit: contain;
                }
            }
        }
        .color1 {
            background-color: #a9587b;
            box-shadow: 0px 74px 70px -20px rgba(#a9587b, 0.16);
        }
        .color2 {
            background-color: #ffa533;
            box-shadow: 0px 74px 70px -20px rgba(#ffa533, 0.2);
        }
        .color3 {
            background-color: #634073;
            box-shadow: 0px 74px 70px -20px rgba(#634073, 0.16);
        }
        .color4 {
            background-color: #f16c47;
            box-shadow: 0px 74px 70px -20px rgba(#f16c47, 0.2);
        }
        .item-md-screen {
            border-radius: 16px;
            padding: 50px 30px;
            position: relative;
            overflow: hidden;
            height: max-content;
            &::before {
                content: "";
                background-image: url(../images/texture.png);
                width: 100%;
                height: 100%;
                inset: 0;
                position: absolute;
                z-index: 0;
                opacity: 0.2;
                mix-blend-mode: overlay;
                pointer-events: none;
            }
            .title {
                font-size: 30px;
                font-weight: $semiBold;
                color: var(--white);
                margin-bottom: 16px;
            }
            .description {
                font-size: 16px;
                font-weight: $medium;
                color: rgba($white, 0.9);
            }
        }
    }
}

.list-text {
    padding-top: 40px;
    li {
        font-size: 30px;
        color: var(--gray-800);
        padding: 10px;
        @include media-breakpoint-down(sm) {
            font-size: 22px;
        }
    }
}

.item-block-ms {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 30px;
    flex-wrap: wrap;
    @include media-breakpoint-down(sm) {
        gap: 20px;
    }
    p {
        font-size: $size-22;
        font-weight: $medium;
    }
    .logo-ms {
        display: flex;
        gap: 30px;
        align-items: center;
        flex-wrap: wrap;
        img {
            width: 70px;
            //height: 50px;
        }
    }
}
