@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

/*
    @FILE: DEFAULT STYLE
    @AUTHER: HASAN SIBAKHI
    @URL: https://dribbble.com/HasanSib

    THIS FILE CONTAINS THE STYLING FOR THE ACTUAL TEMPLATE, THIS
    IS THE FILE YOU NEED TO EDIT TO CHANGE THE LOOK OF THE
    TEMPLATE.

*/
html,
body {
    direction: rtl;
    height: 100%;
}

::selection {
    background-color: #000;
    color: #fff;
}

body {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #0C0B15;
    transition: background-color,.4s all;
    padding-right: 0 !important;
    font: {
        family: "IBM Plex Sans Arabic", sans-serif;
        size: $size-14;
        weight: $normal;
    }
    color: var(--white);
    &.modal-open {
        overflow-y: auto !important;
        padding-right: 0 !important;
        .tc-side_menu {
            padding-right: 0 !important;
        }
    }
    &.bg-snow {
        background-color: var(--light);
    }
}

button,
input,
textarea,
label,
a {
    outline: 0 !important;
    box-shadow: none !important;
}

img {
    user-select: none;
}

i {
    display: inline-block;
}

svg {
    width: 24px;
    height: 24px;
}


#particles-js {
    position: absolute;
    width: 100%;
    pointer-events: none;
    z-index: 10;
}

