@use "../abstracts/variables" as *;

@use "../abstracts/mixins" as *;

.block-one {
    grid-area: b1;
    display: grid;
    gap: 14px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @include media-breakpoint-down(xl) {
        grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-down(sm) {
        grid-template-columns: auto;
        display: block;
        .item-product-home {
            margin-bottom: 14px;
        }
    }
}

.sidebar {
    grid-area: sd;
}

.block-three {
    grid-area: b3;
    display: grid;
    gap: 14px;

    .group-one {
        display: grid;
        gap: 14px;
        grid-template-columns: 2fr 1fr;
        @include media-breakpoint-down(xl) {
            grid-template-columns: auto;
        }
    }
    .group-two {
        display: grid;
        gap: 14px;
        grid-template-columns: 1fr 1fr 1fr;
        @include media-breakpoint-down(xl) {
            grid-template-columns: auto;
        }
    }
}

.block-four {
    grid-area: b4;
    display: grid;
    gap: 14px;
    grid-template-columns: 1fr 1fr 2fr;
    @include media-breakpoint-down(xl) {
        grid-template-columns: auto;
    }
}

.wrapper-products-blocks {
    display: grid;
    gap: 14px;
    padding: 30px 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    // height: 100vh;
    // grid-template-rows: 1fr 1fr 1fr 1fr;

    grid-template-areas:
        "b1 b1 b1 b1"
        "sd b3 b3 b3"
        "sd b3 b3 b3"
        "b4 b4 b4 b4";

    @include media-breakpoint-down(xl) {
        display: flex;
        flex-direction: column;
    }
    @include media-breakpoint-down(sm) {
        padding: 20px;
    }
}

// ITEM
.item-product-home {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    background: linear-gradient(to bottom, rgba($white, 0.02), rgba($white, 0.06));
    //outline: 2px solid rgba($white, 0.05) !important;
    box-shadow: inset -1.5px -1.5px 0px rgba($white, 0.04), inset 1.5px 1.5px 0px rgba($white, 0.04) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    padding: 30px;
    height: 100%;

    .brand-product {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        .main-img {
            width: 100%;
            text-align: center;
            vertical-align: middle;
        }
        .blur-img {
            position: absolute;
            z-index: 0;
            width: 80%;
            filter: blur(60px);
        }
    }
    .title-product {
        color: var(--white);
        font-size: $size-20;
        font-weight: $semiBold;
        margin-bottom: 10px;
    }
    .description {
        color: var(--white);
        font-size: $size-15;
        opacity: 0.8;
        margin-bottom: 0;
    }
    &.tek-item {
        background: linear-gradient(to bottom, #28ddd9, #3ba2d9);
        //border: 2px solid rgba($white, 0.24) !important;
        box-shadow: inset -1.5px -1.5px 0px rgba($white, 0.3), inset 1.5px 1.5px 0px rgba($white, 0.3) !important;
        &::before {
            content: "";
            background-image: url(../images/texture.png);
            width: 100%;
            height: 100%;
            inset: 0;
            position: absolute;
            z-index: 0;
            opacity: 0.24;
            mix-blend-mode: overlay;
            pointer-events: none;
        }
        .description {
            opacity: 0.9;
        }
    }
    &.kyan-item {
        background: linear-gradient(166.96deg, #12d5de -14.38%, #4640d6 103.06%);
        box-shadow: inset -1.5px -1.5px 0px rgba($white, 0.3), inset 1.5px 1.5px 0px rgba($white, 0.3) !important;
        &::before {
            content: "";
            background-image: url(../images/texture.png);
            width: 100%;
            height: 100%;
            inset: 0;
            position: absolute;
            z-index: 0;
            opacity: 0.2;
            mix-blend-mode: overlay;
            pointer-events: none;
        }
        .description {
            opacity: 0.9;
        }
        .custom-chart {
            display: flex;
            justify-content: center;
            align-items: self-end;
            height: 50%;
            gap: 14px;
            position: relative;
            @include media-breakpoint-down(xl) {
                display: none;
            }
            .item {
                .line {
                    display: block;
                    border-radius: 8px;
                    background-color: var(--white);
                    width: 34px;
                    margin-bottom: 10px;
                    // animation: chartbar 2s infinite;
                    // transition-timing-function: ease-in-out;
                }
                &:nth-child(1) {
                    .line {
                        height: 150px;
                        animation: bar-1 30s;
                    }
                }
                &:nth-child(2) {
                    .line {
                        height: 100px;
                        animation: bar-2 20s;
                    }
                }
                &:nth-child(3) {
                    .line {
                        height: 50px;
                        animation: bar-3 10s;
                    }
                }
                h3 {
                    font-size: $size-14;
                    font-weight: $medium;
                    @include en;
                    color: var(--white);
                    margin: 0;
                }
            }
            .item-shape {
                display: flex;
                flex-direction: column;
                gap: 11px;
                animation: blink-1 0.6s both;
                .line {
                    display: block;
                    border-radius: 8px;
                    background-color: rgba($white, 0.2);
                    width: 34px;
                    height: 30px;
                }
                .txt-placeholder {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    justify-content: center;
                    align-items: center;

                    span {
                        display: block;
                        background-color: rgba($white, 0.15);
                        border-radius: 20px;
                        height: 5px;
                        width: 70%;
                    }
                }
            }
        }
    }
    &.techsoup-item {
        text-align: right;
        align-items: start;
        .img-programs {
            display: flex;
            flex-direction: column;
            direction: ltr;
            gap: 16px;
            position: absolute;
            transform: rotate(15deg);
            left: -16px;
            top: 2px;
            z-index: 0;
            .group {
                display: flex;
                gap: 16px;
            }
            img {
                border-radius: 16px;
                width: 70px;
                height: 70px;
            }
            .group {
                &:nth-child(1) {
                    margin-left: 5px;
                }
                &:nth-child(2) {
                    margin-left: -38px;
                }
            }
            @include media-breakpoint-down(sm) {
                transform: rotate(15deg) scale(0.7);
                left: -86px;
                top: 52px;
            }
        }
        .body-product {
            width: 65%;
        }
    }
}

.block-welome-products {
    padding: 0 70px;
    @include media-breakpoint-down(xl) {
        height: 250px;
    }
    .body-product {
        display: flex;
        justify-content: center;
    }
    .title-product {
        font-size: 26px;
        font-weight: $semiBold;
        text-align: center;
        line-height: 43px;
    }
    .category {
        position: absolute;
        top: 35px;
        padding: 9px 16px;
        font-size: $size-14;
        font-weight: $medium;
        background-color: rgba($white, 0.06);
        border-radius: 100px;
        margin-bottom: 15px;
    }
    #animation-siri {
        position: absolute;
        width: 100%;
        height: 370px;
        background-size: cover;
        bottom: 0;
        top: 0;
        z-index: 0;
    }
}

//Sidebar
.sidebar-left {
    padding: 50px 0;
    position: relative;
    height: 100vh;
    @include media-breakpoint-down(xl) {
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 30px 50px;
        padding-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 20px;
        justify-content: center;
    }
    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        .brand-store {
            position: relative;
            display: flex;
            justify-content: center;
            margin-bottom: 24px;
            .main-img {
                width: 230px;
                text-align: center;
                vertical-align: middle;
            }
            .blur-img {
                position: absolute;
                z-index: 0;
                width: 50%;
                filter: blur(60px);
            }
        }
        .saudi-text {
            font-size: $size-15;
            font-weight: $medium;
            color: var(--white);
            background-color: #f2684d;
            padding: 4px 10px;
            display: inline-block;
            width: max-content;
            margin-left: auto;
            margin-right: auto;
        }
    }
    footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .img-tts {
            width: 220px;

            width: 220px;
            position: fixed;
            bottom: 30px;
            z-index: 3;

            @include media-breakpoint-down(xl) {
                width: 150px;
                position: relative;
                bottom: inherit;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        .qr-code {
            position: fixed;
            left: 50px;
            bottom: 30px;
            z-index: 3;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 160px;
            height: 160px;
            border-radius: 14px;
            padding: 14px;
            background: linear-gradient(to bottom, rgba($white, 0.02), rgba($white, 0.06));
            box-shadow: inset -1.5px -1.5px 0px rgba($white, 0.04), inset 1.5px 1.5px 0px rgba($white, 0.04) !important;
            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
            @include media-breakpoint-down(xl) {
                display: none;
            }
        }
    }
}

.loader-page {
    position: fixed !important;
    width: 100%;
    height: 100%;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0b15;
    z-index: 9999;

    &.hidden {
        animation: fadeOut 1s;
        animation-fill-mode: forwards;
        pointer-events: none;
    }
}

//video

.videoonback {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: -1;
    pointer-events: none;
    justify-content: flex-end;
    display: flex;
    justify-content: flex-end;
    //mix-blend-mode: color-dodge;
    video {
        width: 34%;
        position: relative;
    }
    &::before {
        content: "";
        background: radial-gradient(rgba(#0c0b15, 0), rgba(#0c0b15, 1));
        position: fixed;
        width: 30%;
        height: 100%;
        z-index: 1;
        left: 30px;
    }
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

// #click-sound {
//     position: absolute;
//     z-index: -1;
//     opacity: 0;
//     pointer-events: none;
// }
